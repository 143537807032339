html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
}

a {
  text-decoration: none;
}

a:not([class]) {
  cursor: pointer !important;
}

* {
  box-sizing: border-box;
}

#__next {
  height: 100vh;
}

/* total width */
::-webkit-scrollbar {
  background-color: rgba(0, 0, 0, 0);
  width: 11px;
  height: 11px;
  z-index: 999999;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border-radius: 16px;
  border: 2px solid #fff;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

/* scrollbar when element is hovered */
*:hover::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border: 2px solid #fff;
}

/* scrollbar when scrollbar is hovered */
::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
  border: 2px solid #fff;
}
